import React from "react";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import Colors from "../../../assets/styles/colors";
import formatAge from "../../../utils/UnitUtils";
import {FacebookIcon, FacebookShareButton, RedditIcon, RedditShareButton} from "react-share";
import {IconButton} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import {Bounce, toast} from "react-toastify";
import {parsePhoneNumber} from "libphonenumber-js";

const AdoptButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.primaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`
const DonateButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.secondaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`

const CallButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.secondaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    justify-content: center;
    width: 100%;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

const DescriptionContent = styled.p`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 400;
    line-height: 1.7;
    @media (max-width: 768px) {
        line-height: 1.6;
        margin: 0;
    }
`

const TitleContent = styled.h1`
    color: ${colors.textColor};
    text-transform: capitalize;
    font-weight: 700;
    font-size: 50px;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 35px;
        margin: 0;
    }
`

const BreedContent = styled.p`
    color: ${colors.secondaryColor};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 0;
`

const ContentWrapper = styled.section`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 20px;
    @media (max-width: 768px) {
        padding: 10px;
        margin-top: 25px;
    }
`

const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        toast(t('copied_to_clipboard'), {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            theme: "light",
            transition: Bounce,
        })

    } catch (e) {
        toast.error(t(e.code || 'general_error'))
    }
}

const ListingDescription = ({
                                listingId,
                                title,
                                description,
                                pet,
                                onAdoptClick,
                                onDonateClick,
                                onCallClick,
                                phoneNumber
                            }) => {
    let heading;
    if (pet.age) {
        heading = pet ? `${pet.name} • ${formatAge(pet.age)} • ${t(pet.breed.localizationKey)} • ${t(pet.breed.species.localizationKey)}` : ""
    } else {
        heading = pet ? `${pet.name} • ${t(pet.breed.localizationKey)} • ${t(pet.breed.species.localizationKey)}` : ""
    }

    const getFormattedPhoneNumber = () => {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
        return parsedPhoneNumber.formatInternational()
    }

    return (
        <ContentWrapper>
            <BreedContent>{heading}</BreedContent>
            <div style={{
                display: 'flex',
                gap: '16px',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%'
            }}>
                <FacebookShareButton url={`https://share.looki.lt/listings/${listingId}`}
                                     children={<FacebookIcon size={32} round={true}/>}/>
                <RedditShareButton url={`https://share.looki.lt/listings/${listingId}`}
                                   children={<RedditIcon size={32} round={true}/>}/>
                <IconButton
                    onClick={() => copyToClipboard(`https://share.looki.lt/listings/${listingId}`)}
                    sx={{bgcolor: Colors.primaryColor, width: 32, height: 32}}
                >
                    <ContentCopy style={{height: '20px', width: '20px'}}/>
                </IconButton>
            </div>
            <TitleContent>{title}</TitleContent>
            <DescriptionContent>
                {description}
            </DescriptionContent>
            <ButtonsWrapper>
                <AdoptButton
                    onClick={onDonateClick}>
                    {t('button_donate')}
                </AdoptButton>
                <DonateButton
                    onClick={onAdoptClick}>
                    {t('button_adopt')}
                </DonateButton>
            </ButtonsWrapper>
            <CallButton
                onClick={onCallClick}>
                {t('button_call').replace('{phoneNumber}', getFormattedPhoneNumber())}
            </CallButton>
        </ContentWrapper>
    );
};

export default ListingDescription;